import React from 'react'
import Head from '../components/head'
import InlineSVG from '../components/inlineSvg'
import {contactListings, listing, listing__icon, listing__text} from './contact.module.scss'
import Layout from '../components/layout'

const Page404 = () => {
    return (
        <Layout>
            <Head title="Contact" />
            <h1>Page not found</h1>
            <div className={contactListings}>
                <div className={listing}>
                    <InlineSVG style={{color: "#0072b1"}} tag="linkedIn" className={listing__icon}/>
                    <a className={listing__text} href="https://www.linkedin.com/in/nicktheway" target="_blank" rel="noopener noreferrer">in/nicktheway</a>
                </div>
                <div className={listing}>
                    <InlineSVG style={{color: "#333"}} tag="github" className={listing__icon}/>
                    <a className={listing__text} href="https://github.com/nicktheway" target="_blank" rel="noopener noreferrer">nicktheway</a>
                </div>
            </div>
        </Layout>
    )
}

export default Page404